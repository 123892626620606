import { Injectable } from '@angular/core';
import {OktaAuthService} from '@okta/okta-angular';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpAuthClientService {

  constructor(private oktaAuth: OktaAuthService,
              private http: HttpClient) {
  }
  public async get(url: string): Promise<any> {
    const accessToken = await this.oktaAuth.getAccessToken();
    return this.http.get(url,  {
      headers: {
        Authorization: 'Bearer ' + accessToken
      }
    }).toPromise();
  }

  public async post(url: string, body: any): Promise<any> {
    const accessToken = await this.oktaAuth.getAccessToken();
    return this.http.post(url, body, {
      headers: {
        Authorization: 'Bearer ' + accessToken
      }
    }).toPromise();
  }

  // private async oktaGet(url: string): Promise<any> {
  //   const accessToken = await this.oktaAuth.getAccessToken();
  //   return this.http.get(url,  {
  //     headers: {
  //       Authorization: 'Bearer ' + accessToken
  //     }
  //   }).toPromise();
  // }
  //
  // public get(url: string): Observable<any> {
  //    return from(this.oktaGet(url));
  // }

  // private async oktaPost(url: string, body: any): Promise<any> {
  //   const accessToken = await this.oktaAuth.getAccessToken();
  //   return this.http.post(url, body, {
  //     headers: {
  //       Authorization: 'Bearer ' + accessToken
  //     }
  //   }).toPromise();
  // }
  //
  // public post(url: string, body: any): Observable<any> {
  //   return from(this.oktaPost(url, body));
  // }


}
