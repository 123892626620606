import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import {SubmissionFileItem, SubmissionService} from '../submission.service';
import {MatStepper} from '@angular/material';
import {PopupService} from '../popup.service';
import {HttpAuthClientService} from '../http-auth-client.service';


@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.scss']
})
export class AdsComponent implements OnInit {

  public hasAds = false;
  public sfi: SubmissionFileItem = null;

  constructor(private ss: SubmissionService,
              private http: HttpAuthClientService,
              private stepper: MatStepper,
              private popup: PopupService) { }

  ngOnInit() {
    this.sfi = this.ss.getAdsAttachment();
    this.hasAds = !!this.sfi;
  }

  async fileChange(event) {
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    return this.http.post(environment.apiUrl + '/submission/' + this.ss.submission.bfsId
      + '/so/attach/ads/', formData).then((sfi: SubmissionFileItem ) => {
        this.hasAds = true;
        this.sfi = sfi;
        this.ss.updateFromServer();
      },
    (errMsg: string) => {this.popup.showError(errMsg); });
  }

  goNext() {
    this.stepper.next();
  }

  goBack() {
    this.stepper.previous();
  }
}
