import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-identify-filer',
  templateUrl: './identify-filer.component.html',
  styleUrls: ['./identify-filer.component.scss']
})
export class IdentifyFilerComponent implements OnInit {

  public identifyFilerForm;

  constructor(private fb: FormBuilder) {
    this.identifyFilerForm = this.fb.group({
      emailAddress: [ '', Validators.email],
      titleName: [''],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      suffixName:  [''],
      captcha: [''],
      iUnderstand: [false]
    });
  }
  public titles = ['', 'Mr.', 'Ms.', 'Mrs.', 'Miss', 'Dr.'];

  ngOnInit() {
  }

  goNext() {

  }

  getFullName() {
    const f = this.identifyFilerForm.value;
    // return JSON.stringify(f);
    return `"${f.titleName} ${f.firstName} ${f.middleName} ${f.lastName} ${f.suffixName}" <${f.emailAddress}>`;
  }
}
