// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { version as packageJsonVersion } from '../../package.json';

export const environment = {
  // apiUrl: 'http://aws.helg.com/bfs-services',
  apiUrl: 'https://bfs.helg.com/bfs-services',
  // apiUrl: 'http://localhost:8080/bfs-services',
  production: false,
  version: packageJsonVersion,
  oidc: {
    clientId: '0oab2owguUybGIEZu4x6',
    // issuer: 'https://dev-423711.okta.com/oauth2/default',
    issuer: 'https://idm.helg.com/oauth2/default',
    // redirectUri: 'http://localhost:4200/implicit/callback',
    redirectUri: 'https://bfs.helg.com/implicit/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    testing: {
      disableHttpsCheck: true
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
