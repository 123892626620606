import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import {TransferDataService} from '../transfer-data.service';
import {RestService} from '../rest.service';


@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {

  hideVerification: boolean;

  ifValidate: boolean;

  email: string;

  verificationCode: string;

  bfsId: number;

  constructor( public dialogRef: MatDialogRef<VerificationComponent>, private router: Router,
               private dataService: TransferDataService, private service: RestService) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.hideVerification = false;
    this.ifValidate = false;
  }

  close() {
    this.dialogRef.close();
  }

  verification(): void {
    if (this.email) {
      this.service.emailVerfication(this.email).then((data: {}) => {
      });
    }
    this.hideVerification = true;

  }

  verify() {
    if (this.verificationCode) {
      this.service.validatePin( this.verificationCode).then((data: {}) => {
      });
    }
    this.dialogRef.close();
    this.router.navigate(['/upload']);
  }

  yes() {

     this.ifValidate = true;
  }
  no() {
    this.dialogRef.close();
    this.router.navigate(['/upload']);
  }



}
