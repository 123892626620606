import {Component, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {MatStep, MatStepper} from '@angular/material';

@Component({
  selector: 'app-file-followon',
  templateUrl: './file-followon.component.html',
  styleUrls: ['./file-followon.component.scss']
})
export class FileFollowonComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;

  tabs = [
    '/fileFollowon/appConf',
    '/fileFollowon/upload',
    '/fileFollowon/fees',
    '/fileFollowon/sign',
    '/fileFollowon/verifyEmail',
    '/fileFollowon/fpng'
  ];

  private initialScreenLabel: string;

  constructor(private location: Location) {
    const locationPath = location.path(false);
    this.initialScreenLabel = locationPath.substring(locationPath.lastIndexOf('/') + 1);
  }

  private setStepperByLocation() {
    this.tabs.forEach((path, index) => {
      if (this.location.isCurrentPathEqualTo(path)) {
        this.stepper.selectedIndex = index;
      }
    });
  }

  ngOnInit() {
    // navigate to proper step when URL location changes
    // the stepper.selected can not be set inside ngOnInit() caller context, so we do it afterwards
    setTimeout(() => {
      this.setStepperByLocation();
    }, 0);

    this.location.subscribe( () => this.setStepperByLocation());
  }

  selectionChange($event: StepperSelectionEvent) {
    // change URL location when user navigates
    this.location.go(this.tabs[$event.selectedIndex]);
  }
}
