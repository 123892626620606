import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, ValidatorFn, Validators} from '@angular/forms';
import {FilerIdentification, Submission, SubmissionService} from '../submission.service';
import {environment} from '../../environments/environment';
import {MatStepper} from '@angular/material';
import {PopupService} from '../popup.service';
import {HttpAuthClientService} from '../http-auth-client.service';

@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class SignComponent implements OnInit {

  public signForm;
  constructor(private ss: SubmissionService,
              private fb: FormBuilder,
              private http: HttpAuthClientService,
              private stepper: MatStepper,
              private popup: PopupService) {
    const fi = !this.ss.submission ? null : this.ss.submission.filerIdentification;
    this.signForm = this.fb.group({
      attorneyDocketNumber: [fi == null ? '' : fi.attorneyDocketNumber],
      email: [fi == null ? '' : fi.email, Validators.email],
      signerName: [fi == null ? '' : fi.signerName, Validators.required],
      signature: [fi == null ? '' : fi.signature, [Validators.required, this.textInSlashesValidator()]]
    });
  }

  ngOnInit() {
  }

  textInSlashesValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      // console.log(control.value);
      const v = control.value as string;
      const invalid = (v == null || v.length <= 2 || v.charAt(0) !== '/' || v.charAt(v.length - 1) !== '/');
      return invalid ? {forbiddenName: {value: control.value}} : null;
    };
  }

  goNext() {
    const fi = new FilerIdentification();
    Object.assign(fi, this.signForm.value);
    this.http.post(environment.apiUrl + '/submission/' + this.ss.bfsId + '/ve/sign', fi).then(
      (sub: Submission) => {
        this.ss.submission = sub;
        this.stepper.next();
      },
      (errMsg: string) => {this.popup.showError(errMsg); });
  }

  goBack() {
    this.stepper.previous();
  }
}
