import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { environment } from '../../environments/environment';
import { OktaAuthService } from '@okta/okta-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  environment: any;
  public userEmail: string;
  public isAuthenticated: boolean;

  constructor(public oktaAuth: OktaAuthService) {
    this.environment = environment;
    // Subscribe to authentication state changes
    this.oktaAuth.$authenticationState.subscribe(
      (isAuthenticated: boolean)  =>  {
        console.log('header.isAuthenticated:' + isAuthenticated);
        this.isAuthenticated = isAuthenticated;
        if ( isAuthenticated ) {
          this.oktaAuth.getUser().then( userClaims =>
            this.userEmail = userClaims !== undefined ? userClaims.email : '');
        } else {
          this.userEmail = '';
        }
      }
    );
  }

  myUsptoUrl(): string {return 'https://my.uspto.gov'; }
  isRetrievalDisabled(): boolean {return true; }

  logout() {
    this.oktaAuth.logout('/');
  }

  x(): boolean {return false; }
  toggleHelp() {}

  async getUserEmail(): Promise<string> {
    const userClaims = await this.oktaAuth.getUser();
    if (userClaims !== undefined && userClaims.email !== undefined) {
      return userClaims.email;
    }
    return 'Not logged in';
  }

  async ngOnInit() {
    console.log('header.ngOnInit');
    this.oktaAuth.getUser().then( userClaims => {
      this.userEmail = userClaims !== undefined ? userClaims.email : '';
      this.isAuthenticated = true; }
      );
  }

}
