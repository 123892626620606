import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class TransferDataService {




  constructor() {
  }

  private bfsId: string;


  setBfsId(bfsId) {
    this.bfsId = bfsId;
  }

  getBfsID() {
    const temp = this.bfsId;
    this.clearData();
    return temp;
  }


  clearData() {
    this.bfsId = null;
  }
}
