import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PopupService} from '../popup.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private popup: PopupService) {
    console.log('TestComponent.helloStr=' + this.route.snapshot.data.helloStr);
    console.log('TestComponent.groups=' + typeof (this.route.snapshot.data.groups));
  }

  ngOnInit() {
  }

  showError(): void {
    this.popup.showError('This is an error message');
  }
}
