import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Resolve} from '@angular/router';

// Read-only. Fee schedule, which is returned by 'bfsScheduleGroups' REST call is of Group[] type
// Sample Groups are 'Patent', 'PCT', 'Hague'
export interface Group {
  name: string;
  groupItems: GroupItem[];
}

// Read-only. Sample GroupItems are: 'Patenmt filing fees', 'Patent examination fees', etc
export interface GroupItem {
  name: string;
  feeItemGroups: FeeItemGroup[];
}

// Read-only. Group of items for all 3 entity sizes
export interface FeeItemGroup {
  code: string; // all but first symbol of fee code - these are the same for all 3 entity sizes
  cfr: string;
  desc: string;
  maxCount: string;
  maxQty: string;
  partialPayment: string;
  varPayment: string;
  feeItems: FeeItem[];
}

// Read-only. Fee item for given entity size
export interface FeeItem {
  code: string;
  amount: number;
}

@Injectable({
  providedIn: 'root'
})
export class FeeScheduleResolverService implements Resolve<Group[]> {

  groups: Group[];
  constructor(private http: HttpClient) {
  }

  resolve(): Observable<Group[]> {
    const ret: Observable<Group[]> = this.http.get<Group[]>(environment.apiUrl + '/fees/bfsScheduleGroups');
    ret.toPromise().then((groups) => this.groups = groups);
    return ret;
  }
}


