import { Component, OnInit } from '@angular/core';
import { BScreen } from '../navigation.service';
import { Submission, SubmissionService } from '../submission.service';
import { environment } from '../../environments/environment';
import {Router} from '@angular/router';
import {PopupService} from '../popup.service';
import {HttpAuthClientService} from '../http-auth-client.service';

@Component({
  selector: 'app-filing-type',
  templateUrl: './filing-type.component.html',
  styleUrls: ['./filing-type.component.scss']
})
export class FilingTypeComponent implements OnInit {

  constructor(private router: Router,
              private ss: SubmissionService,
              private http: HttpAuthClientService,
              private popup: PopupService) { }

  ngOnInit() {
  }

  async newApplicationFiling() {
    this.http.get(environment.apiUrl + '/newsubmission/New').then(
      (sub: Submission) => {
        this.ss.submission = sub;
        console.log(sub);
        this.router.navigate([BScreen.FILE_NEW_APP]);
      });
  }

  async followOnFiling() {
    this.http.get(environment.apiUrl + '/newsubmission/FollowOn').then(
      (sub: Submission) => {
      this.ss.submission = sub;
      console.log(sub);
      this.router.navigate([BScreen.FILE_FOLLOWON]);
    },
    (errMsg: string) => {this.popup.showError(errMsg); });
  }
}
