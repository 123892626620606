import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

export interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-show-styles',
  templateUrl: './show-styles.component.html',
  styleUrls: ['./show-styles.component.scss']
})
export class ShowStylesComponent implements OnInit {

  constructor(private formBuilder: FormBuilder) {
  }

  // >> Stepper
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  // << Stepper

  // >> Expansion Panel
  panelOpenState = false;
  // << Expansion Panel

  // >> Select start
  foods: Food[] = [
    {value: 'steak-0', viewValue: 'Steak'},
    {value: 'pizza-1', viewValue: 'Pizza'},
    {value: 'tacos-2', viewValue: 'Tacos'}
  ];
  // << Select end
  // >> Autocomplete start
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three', 'Throw', 'Then'];
  filteredOptions: Observable<string[]>;

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    // >> Stepper
    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    // << Stepper
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
  // << Autocomplete end
}
