import {Component, OnInit} from '@angular/core';
import {BScreen} from '../navigation.service';
import {FeeRequestItem, Submission, SubmissionService} from '../submission.service';
import {environment} from '../../environments/environment';
import {MatStepper} from '@angular/material';
import {Router} from '@angular/router';
import {PopupService} from '../popup.service';
import {HttpAuthClientService} from '../http-auth-client.service';

@Component({
  selector: 'app-fpng',
  templateUrl: './fpng.component.html',
  styleUrls: ['./fpng.component.scss']
})
export class FpngComponent implements OnInit {

  constructor(private http: HttpAuthClientService,
              private ss: SubmissionService,
              private router: Router,
              private stepper: MatStepper,
              private popup: PopupService) { }

  async ngOnInit() {
    this.ss.submission = await this.http.get(environment.apiUrl + '/submission/' +
      this.ss.bfsId + '/ve/freezeforpayment');
  }

  getTotalFees(): number {
    console.log(this.ss.submission);
    let grandTotal = 0;
    if (this.ss.submission.feeRequestItems !== null) {
      this.ss.submission.feeRequestItems.forEach((feeRequestItem: FeeRequestItem) =>  {
        grandTotal += feeRequestItem.feeCodeAmount * feeRequestItem.feeCodeQuantity;
      });
    }
    return grandTotal;
  }

  goFpng() {
  }

  goBack() {
    this.http.get(environment.apiUrl + '/submission/' + this.ss.submission.bfsId + '/ve/unfreezefrompayment').then(
      (sub: Submission) => {
        this.ss.submission = sub;
        this.stepper.previous();
      });
  }

  goSubmit() {
    this.http.get(environment.apiUrl + '/submission/' + this.ss.bfsId + '/ve/submit').then(
      (sub: Submission) => {
        this.ss.submission = sub;
        this.router.navigate([BScreen.RECEIPT]);
      },
      (errMsg: string) => {this.popup.showError(errMsg); });
  }
}
