import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { environment } from '../../environments/environment';
import {FeeRequestItem, Submission, SubmissionService} from '../submission.service';
import {MatStepper} from '@angular/material';
import {Group, FeeItemGroup, FeeItem } from '../fee-schedule-resolver.service';
import {ActivatedRoute} from '@angular/router';
import {PopupService} from '../popup.service';
import {HttpAuthClientService} from '../http-auth-client.service';

enum ControlType {
  checkBox = 1,
  qtyInt,
  amountDollar
}

export class FeePaymentLine {
  public qty: number;
  public amount: number;
  public controlType: ControlType;
  constructor(
    public item: FeeItem,
    public group: FeeItemGroup
  ) {
    this.init();
  }

  init() {
    if ('Y' === this.group.varPayment ) {
      this.qty = 1;
      this.amount = 0;
      this.controlType = ControlType.amountDollar;
    } else {
      this.qty = 0;
      this.amount = this.item.amount;
      if ('1' === this.group.maxCount && '1' === this.group.maxQty) {
        this.controlType = ControlType.checkBox;
      } else {
        this.controlType = ControlType.qtyInt;
      }
    }
  }
}

export class FeePaymentSheet {
  line = new Map<string, FeePaymentLine>(); // key = fee code
  init() {
    this.line.forEach((feePaymentLine: FeePaymentLine) => {
      feePaymentLine.init();
    });
  }

  addLine(item: FeeItem, group: FeeItemGroup) {
    this.line.set(item.code, new FeePaymentLine(item, group));
  }
}
/*
export class FeeItemDetails {
  constructor(
    public item: FeeItem,
    public group: FeeItemGroup) {}
}

export class FeeSheetLine {
  constructor(
    public code: string,
    public qty: number,
    public amount: number) {}
}
*/
@Component({
  selector: 'app-fees',
  templateUrl: './fees.component.html',
  styleUrls: ['./fees.component.scss']
})
export class FeesComponent implements OnInit {
  public groups: Group[];
  feePaymentSheet = new FeePaymentSheet();
  controlType = ControlType; // to have it available in html
  displayedColumns = ['code', 'cfr', 'desc', 'fee', 'input', 'total'];
  entitySize = 0; // 0=>regular, 1=>small, 2=> micro

  constructor(private http: HttpAuthClientService,
              private changeDetectorRefs: ChangeDetectorRef,
              private ss: SubmissionService,
              private route: ActivatedRoute,
              private stepper: MatStepper,
              private popup: PopupService) {
    this.groups = this.route.snapshot.data.groups;

    if (this.groups === undefined) {
      console.log('No fee schedule provided');
    } else {
      for (const gr of this.groups) {
        for (const gi of gr.groupItems) {
          for (const ig of gi.feeItemGroups) {
            if (ig.feeItems[0] == null) {
              // console.log('null first object in ' + ig.code + ':' + ig.desc);
            }
            for (const i of ig.feeItems) {
              if (null !== i) {
                this.feePaymentSheet.addLine(i, ig);
              }
            }
          }
        }
      }
    }
    // fill feeSheet data from this.ss.submission
    if (!!this.ss.submission.feeRequestItems) {
      for (const feeRequestItem of this.ss.submission.feeRequestItems) {
        const feePaymentLine = this.feePaymentSheet.line.get(feeRequestItem.feeCode);
        feePaymentLine.qty = feeRequestItem.feeCodeQuantity;
        feePaymentLine.amount = feeRequestItem.feeCodeAmount;
      }
    }

  }

  ngOnInit() {
  }

  setEntitySize(s: number): boolean { // also clears all existing payment lines
    const doRefresh = this.entitySize !== s;
    this.entitySize = s;
    if (doRefresh) {
      this.feePaymentSheet.init();
      this.changeDetectorRefs.detectChanges();
    }
    return true;
  }

  // If an item does not have discounted code, return an undiscounted
  getFeeItem(feeItemGroup: FeeItemGroup): FeePaymentLine {
    let es = this.entitySize;
    while (feeItemGroup.feeItems[es] == null) {
      es = es - 1;
    }

    return this.feePaymentSheet.line.get(feeItemGroup.feeItems[es].code);
  }

   getFeeCodeTotal(code: string): number {
     const feePaymentLine = this.feePaymentSheet.line.get(code);
     if (feePaymentLine == null) {
       return 0;
     }
     return feePaymentLine.amount * feePaymentLine.qty;
   }

  getFeeGrandTotal(): number {
    let grandTotal = 0;
    this.feePaymentSheet.line.forEach((feePaymentLine: FeePaymentLine) => {
      grandTotal += feePaymentLine.amount * feePaymentLine.qty;
    });
    return grandTotal;
  }

  getFeeRequestItems(): FeeRequestItem[] {
    const feeRequestItems: FeeRequestItem[] = [];

    this.feePaymentSheet.line.forEach((feePaymentLine: FeePaymentLine) => {
      if (feePaymentLine.amount !== 0 && feePaymentLine.qty !== 0) {
        feeRequestItems.push(new FeeRequestItem(feePaymentLine.item.code, feePaymentLine.amount, feePaymentLine.qty));
      }
    });
    return feeRequestItems;
  }

  async goNext() {
    this.http.post(environment.apiUrl + '/submission/' +
      this.ss.bfsId + '/ve/setfeerequestitems', this.getFeeRequestItems()).then((sub: Submission) => {
      this.ss.submission = sub;
      this.stepper.next();
      },
      (errMsg: string) => {this.popup.showError(errMsg); });

  }

  goBack() {
    this.stepper.previous();
  }
}
