import { NgModule } from '@angular/core';

import {
  MatButtonModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatMenuModule,
  MatSelectModule,
  MatRadioModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  MatTableModule,
  MatInputModule,
  MatExpansionModule,
  MatDialogModule,
  MatStepperModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatGridListModule,
  MatSnackBarModule
} from '@angular/material';


@NgModule({

  imports: [
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatTableModule,
    MatInputModule,
    MatExpansionModule,
    MatDialogModule,
    MatStepperModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatSnackBarModule
  ],
  exports: [
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatTableModule,
    MatInputModule,
    MatExpansionModule,
    MatDialogModule,
    MatStepperModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatGridListModule
  ]
})
export class MaterialModule {}
