import {Component, OnInit} from '@angular/core';
import {Submission, SubmissionService} from '../submission.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {MatStepper} from '@angular/material';
import {PopupService} from '../popup.service';
import {HttpAuthClientService} from '../http-auth-client.service';

interface NewAppTypeGroup {
  name: string;
  descr: string;
}

class NewAppType {
  name: string;
  descr: string;
  group: NewAppTypeGroup;
  category: string;
}

class NewAppGroup {
  constructor(public group: NewAppTypeGroup) {
  }
  appTypes: NewAppType[] = [];
}

@Component({
  selector: 'app-new-app-type',
  templateUrl: './new-app-type.component.html',
  styleUrls: ['./new-app-type.component.scss']
})
export class NewAppTypeComponent implements OnInit {

  private newAppTypes: NewAppType[];
  public newAppGroups: NewAppGroup[] = [];

  constructor(private ss: SubmissionService,
              private http: HttpClient,
              private httpAuth: HttpAuthClientService,
              private stepper: MatStepper,
              private popup: PopupService) {
  }

  async ngOnInit() {
    this.newAppTypes = await this.http.get<NewAppType[]>(environment.apiUrl + '/info/types/application').toPromise() ;

    for (const at of this.newAppTypes) {
      let nagIdx = this.newAppGroups.findIndex(nag => nag.group.name === at.group.name );
      if (nagIdx < 0) {
        nagIdx = this.newAppGroups.push(new NewAppGroup(at.group)) - 1;
      }
      this.newAppGroups[nagIdx].appTypes.push(at);
    }
  }

  async fileApplication(appType: string) {
    this.httpAuth.get(environment.apiUrl + '/submission/' +
      this.ss.bfsId + '/so/newapp/' + appType).then((sub: Submission) => {
        this.ss.submission = sub;
        this.stepper.next();
    },
    (errMsg: string) => {this.popup.showError(errMsg); });
  }
}
