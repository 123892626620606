import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CdkStepperModule } from '@angular/cdk/stepper';

import { HomeComponent } from './home/home.component';
import { AdsComponent } from './ads/ads.component';
import { ErrorComponent } from './error/error.component';
import { FilingTypeComponent } from './filing-type/filing-type.component';
import { UploadComponent} from './upload/upload.component';
import { ReviewComponent } from './review/review.component';
import { ShowStylesComponent } from './show-styles/show-styles.component';
import { VerificationComponent } from './verification/verification.component';
import { MaterialModule } from './material/material.module';
import { FeesComponent } from './fees/fees.component';
import { SubmissionComponent } from './submission/submission.component';
import { NewAppTypeComponent } from './new-app-type/new-app-type.component';
import { SignComponent } from './sign/sign.component';
import { BScreen } from './navigation.service';
import { AppConfComponent } from './app-conf/app-conf.component';
import { FpngComponent } from './fpng/fpng.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { FileNewAppComponent } from './file-new-app/file-new-app.component';
import { FileFollowonComponent } from './file-followon/file-followon.component';
import { IdentifyFilerComponent } from './identify-filer/identify-filer.component';
import { FeeScheduleResolverService } from './fee-schedule-resolver.service';
import { TestComponent } from './test/test.component';
import { TestResolverService } from './test-resolver.service';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import {
  OKTA_CONFIG,
  OktaAuthGuard,
  OktaAuthModule,
  OktaCallbackComponent,
} from '@okta/okta-angular';
import { environment } from '../environments/environment';

const routes: Routes = [
  // active screens
  { path: BScreen.HOME,                   component: HomeComponent },
  { path: BScreen.FILING_TYPE,            component: FilingTypeComponent ,
    canActivate: [ OktaAuthGuard ] },
  { path: BScreen.FILE_NEW_APP,           component: FileNewAppComponent ,
    resolve: { groups: FeeScheduleResolverService },
    canActivate: [ OktaAuthGuard ] },
  { path: BScreen.FILE_NEW_APP + '/:screen',           component: FileNewAppComponent ,
    resolve: { groups: FeeScheduleResolverService },
    canActivate: [ OktaAuthGuard ] },
  // { path: BScreen.FILE_NEW_APP + '/:id',  component: FileNewAppComponent ,
  //   resolve: { helloStr: TestResolverService, groups: FeeScheduleResolverService }},
  { path: BScreen.FILE_FOLLOWON,          component: FileFollowonComponent ,
    resolve: { groups: FeeScheduleResolverService },
    canActivate: [ OktaAuthGuard ] },
  { path: BScreen.FILE_FOLLOWON + '/:screen',          component: FileFollowonComponent ,
    resolve: { groups: FeeScheduleResolverService },
    canActivate: [ OktaAuthGuard ] },
  // { path: BScreen.FILE_FOLLOWON + '/:id', component: FileFollowonComponent },
  { path: BScreen.RECEIPT,                component: ReceiptComponent ,
    canActivate: [ OktaAuthGuard ] },

  // sub-screens are now navigared via stepper on FileNewAppComponent/FileFollowonComponent
  // { path: BScreen.IDENTIFY_FILER,         component: IdentifyFilerComponent},
  // { path: BScreen.ADS,                    component: AdsComponent },
  // { path: BScreen.UPLOAD,                 component: UploadComponent },
  // { path: BScreen.FEES,                   component: FeesComponent },
  // { path: BScreen.NEW_APP_TYPE,           component: NewAppTypeComponent },
  // { path: BScreen.SIGN,                   component: SignComponent },
  // { path: BScreen.APPCONFNUM,             component: AppConfComponent },

  // mock
  { path: BScreen.FPNG,                   component: FpngComponent,
    canActivate: [ OktaAuthGuard ] },

  // obsoleted screens
  // { path: 'review',                       component: ReviewComponent },
  // test screens
  { path: 'showstyles',                   component: ShowStylesComponent },
  { path: 'submission',                   component: SubmissionComponent ,
    canActivate: [ OktaAuthGuard ] },
  { path: 'htest',                        component: TestComponent,
                                          resolve: { helloStr: TestResolverService, groups: FeeScheduleResolverService }},
  { path: 'htest2',                       component: FeesComponent,
                                          resolve: { helloStr: TestResolverService, groups: FeeScheduleResolverService }},
  { path: 'implicit/callback',            component: OktaCallbackComponent },

  // do not remove!
  { path: '**',                           component: ErrorComponent }
];

export function resolveFeeSchedule(feeScheduleResolverService: FeeScheduleResolverService): () => void {
  return () => feeScheduleResolverService.resolve();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ErrorComponent,
    AdsComponent,
    FilingTypeComponent,
    ReviewComponent,
    ShowStylesComponent,
    UploadComponent,
    VerificationComponent,
    FeesComponent,
    SubmissionComponent,
    NewAppTypeComponent,
    SignComponent,
    AppConfComponent,
    FpngComponent,
    ReceiptComponent,
    FileNewAppComponent,
    FileFollowonComponent,
    IdentifyFilerComponent,
    TestComponent,
    VerifyEmailComponent
  ],
  imports: [
    RouterModule.forRoot(routes,  {/*enableTracing: true, */useHash: false }),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    CdkStepperModule,
    MaterialModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    OktaAuthModule
  ],
  entryComponents: [
    VerificationComponent
  ],
  providers: [
    FeeScheduleResolverService,
    { provide: APP_INITIALIZER,
      useFactory: resolveFeeSchedule,
      deps: [FeeScheduleResolverService],
      multi: true
    },
    { provide: OKTA_CONFIG, useValue: environment.oidc },
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
