import { Component, OnInit } from '@angular/core';
import { SubmissionService } from '../submission.service';


@Component({
  selector: 'app-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.scss']
})



export class SubmissionComponent implements OnInit {


  Object = Object;

  constructor(public ss: SubmissionService) { }

  ngOnInit() {


  }






}


