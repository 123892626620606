import {Injectable, Input} from '@angular/core';
import {environment} from '../environments/environment';
import {BCookieService} from './b-cookie.service';
import {HttpAuthClientService} from './http-auth-client.service';

class NewApplicationType {
    name: string;
    group: string;
    category: string;
    descr: string;
}

export class FollowOnSubmission {
  applicationNumber: string;
  confirmationNumber: string;
}

export class DocumentType {
  id: string;
  code: string;
  desc: string;
  descext: string;
}

export class SubmissionItemDocument {
  documentCode: DocumentType;
  startPage: number;
  endPage: number;
}

export class SubmissionItemDocumentInput {
  documentCode: string;
  startPage: string;
  endPage: string;
}

export class SubmissionFileItem {
  id: number;
  fileName: string;
  bfsId: string;
  fileSize: number;
  contentType: string;
  pageTotalQuantity: number;
  documents: SubmissionItemDocument[];
  sha256digestText: string;
  uploadIndicator: string;
}

export class FilerIdentification {
  email: string;
  signerName: string;
  signature: string;
  attorneyDocketNumber: string;
}

export class FeeRequestItem {
  constructor( public feeCode: string,
               public feeCodeAmount: number,
               public feeCodeQuantity: number) {}
}

export class Submission {
  bfsId: string;
  newApplicationType: NewApplicationType;
  followOnSubmission: FollowOnSubmission;
  state: string;
  attachments: SubmissionFileItem[];
  filerIdentification: FilerIdentification;
  feeRequestItems: FeeRequestItem[];
  lastModifiedTimestamp: string;

  public getGrandTotalFee(): number {
    let grandTotal = 0;
    if (this.feeRequestItems !== null) {
      this.feeRequestItems.forEach((feeRequestItem: FeeRequestItem) =>  {
        grandTotal += feeRequestItem.feeCodeAmount * feeRequestItem.feeCodeQuantity;
      });
    }
    return grandTotal;
  }
}

export class FilingResponse {
  isOk: boolean;
  errorMsg: string;
  submission: Submission;
}

@Injectable({
  providedIn: 'root'
})

export class SubmissionService {
  constructor(private http: HttpAuthClientService) {}

  public get submission(): Submission {
    if (this.submissionP == null) {
      // this.submissionP = new Submission();
      this.retrieveSubmission();
      console.log('Retrieve submission: ' + this.submissionP);
     }
    return this.submissionP;
  }
  public set submission(s: Submission) {
    this.submissionP = s;
    BCookieService.set(s.bfsId);
  }

  public get bfsId(): string {
    if (!!this.submissionP) {
      return this.submissionP.bfsId;
    }
    return BCookieService.get();
  }
  @Input() format: string;
  private submissionP: Submission = null;

  public static extractAdsAttachment(sub: Submission): SubmissionFileItem {
    let ret: SubmissionFileItem = null;
    if (!!sub && !! sub.attachments) {
      sub.attachments.forEach(
        (att) => {
          if (!!att.documents &&
            att.documents.length === 1 &&
            att.documents[0].documentCode.code === 'ADS') {
            ret = att;
          }
        });
    }
    return ret;
  }

  async retrieveSubmission() {
    this.submissionP = await this.http.get(environment.apiUrl + '/submission/'
      + BCookieService.get() + '/so/get') as Submission;
  }

  public updateFromServer() {
    const bfsId = this.bfsId;
    if ( !!bfsId ) {
    this.http.get(environment.apiUrl + '/submission/' + bfsId + '/so/get').then(
      (newSub: Submission) => {
        if (newSub != null) {
          this.submission = newSub;
          console.log('updateFromServer return');
          console.log(newSub);
        }
      });
  }
  }
  public getAdsAttachment(): SubmissionFileItem {
    return SubmissionService.extractAdsAttachment(this.submission);
  }

  public toJSON() {return JSON.stringify(this.submission, null, 4); }
}
