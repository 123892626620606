import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {FollowOnSubmission, Submission, SubmissionService} from '../submission.service';
import {environment} from '../../environments/environment';
import {MatStepper} from '@angular/material';
import {PopupService} from '../popup.service';
import {HttpAuthClientService} from '../http-auth-client.service';

@Component({
  selector: 'app-app-conf',
  templateUrl: './app-conf.component.html',
  styleUrls: ['./app-conf.component.scss']
})
export class AppConfComponent implements OnInit {

  public appConfForm;

  constructor(private ss: SubmissionService,
              private fb: FormBuilder,
              private http: HttpAuthClientService,
              private stepper: MatStepper,
              private popup: PopupService) {
    const fa = !!this.ss.submission ? this.ss.submission.followOnSubmission : null;
    this.appConfForm = this.fb.group({
      applicationNumber: [fa == null ? '' : fa.applicationNumber, Validators.required],
      confirmationNumber: [fa == null ? '' : fa.confirmationNumber, Validators.required],
    });
  }

  ngOnInit() {
  }

  async goNext() {
    const foa = new FollowOnSubmission();
    foa.applicationNumber = this.appConfForm.value.applicationNumber;
    foa.confirmationNumber = this.appConfForm.value.confirmationNumber;

    this.http.post(environment.apiUrl + '/submission/' +
      this.ss.bfsId + '/so/followon', foa).then((sub: Submission) => {
      this.ss.submission = sub;
      this.stepper.next();
    },
     (errMsg: string) => {this.popup.showError(errMsg); });
  }

  goBack() {
    this.stepper.previous();
  }

}
