import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

export enum BScreen {
  HOME = '',
  FILE_NEW_APP = 'fileNewApp',
  FILE_FOLLOWON = 'fileFollowon',
  FILING_TYPE = 'filingType',
  NEW_APP_TYPE = 'newAppType',
  ADS = 'ads',
  UPLOAD = 'upload',
  FEES = 'fees',
  SIGN = 'sign',
  FPNG = 'fpng',
  APPCONFNUM = 'appConfNum',
  RECEIPT = 'receipt',
  IDENTIFY_FILER = 'identifyFiler'
}

const navPathDefault = [ BScreen.HOME, BScreen.FILING_TYPE];

// export const navPathNewApp =
//   [BScreen.NEW_APP_TYPE, BScreen.ADS,
//     BScreen.UPLOAD, BScreen.FEES, BScreen.SIGN, BScreen.FPNG, BScreen.RECEIPT];
//
// export const navPathFollowon =
//   [BScreen.HOME, BScreen.FILING_TYPE, BScreen.APPCONFNUM,
//     BScreen.UPLOAD, BScreen.FEES, BScreen.SIGN, BScreen.FPNG, BScreen.RECEIPT];

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private screens: BScreen[];
  constructor(private router: Router) {
    this.screens = navPathDefault;
  }

  // setNewAppNavPath() {
  //   this.screens = navPathNewApp;
  // }
  // setFollowOnNavPath() {
  //   this.screens = navPathFollowon;
  // }

  goNext(currentScreen: BScreen) {
    const idxNext = this.screens.findIndex( x => ( x === currentScreen)) + 1;
    if (idxNext < this.screens.length) {
      const nextScreen = this.screens[idxNext];
      // console.log('nextScreen[' + currentScreen + ']=' + nextScreen);
      this.router.navigate([ nextScreen], );
    }
  }

  goBack(currentScreen: BScreen) {
    const idxPrev = this.screens.findIndex( x => ( x === currentScreen)) - 1;
    if (idxPrev >= 0) {
      const prevScreen = this.screens[idxPrev];
      this.router.navigate([prevScreen]);
    }
  }
}
