import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BCookieService {

  constructor() { }

  public static set(bfsId: string) {
    document.cookie = `BFSID=${bfsId}`;
  }

  public static get() {
    const name = 'BFSID=';
    const ca = decodeURIComponent(document.cookie).split(';');
    for (let c of ca) {
        c = c.trim();
        if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }
  public static remove() {
    document.cookie = 'BFSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
  }
}
