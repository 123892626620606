import { Component, OnInit } from '@angular/core';
import {SubmissionService} from '../submission.service';
import {AbstractControl, FormBuilder, ValidatorFn} from '@angular/forms';
import {MatStepper} from '@angular/material';
import {environment} from '../../environments/environment';
import {PopupService} from '../popup.service';
import {HttpAuthClientService} from '../http-auth-client.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  public pinCodeForm;
  public emailAddress;
  constructor(private ss: SubmissionService,
              private fb: FormBuilder,
              private http: HttpAuthClientService,
              private stepper: MatStepper,
              private popup: PopupService) {
    this.pinCodeForm = this.fb.group( {
      pinCode: ''
      }
    );
  }

  ngOnInit() {
  }


  pinCode4DigitsValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      // console.log(control.value);
      const v = control.value as string;
      const invalid = (v == null || v.length !== 4 || !/^\d+$/.test(v));
      return invalid ? {forbiddenName: {value: control.value}} : null;
    };
  }

  goNext() {
    // check whether result is successful
    console.log('pincode:' + this.pinCodeForm.value.pinCode);
    // post PIN code,
    this.http.get(environment.apiUrl + '/submission/' + this.ss.bfsId + '/authenticate/' + this.pinCodeForm.value.pinCode)
      .then(
      (/* ret: string */) => {
        this.stepper.next();
      },
      (errMsg: string) => {this.popup.showError(errMsg); });
}

  goBack() {
    this.stepper.previous();
  }
}
