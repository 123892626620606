import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NewSubmission } from './home/newsubmission';
import { DocumentTypes } from './document-types';
import {SubmissionFileItem, SubmissionService} from './submission.service';
import {environment} from '../environments/environment';
import {HttpAuthClientService} from './http-auth-client.service';

@Injectable({
  providedIn: 'root',
})

@Injectable()
export class RestService {

  constructor(private http: HttpClient,
              private ss: SubmissionService,
              private httpAuth: HttpAuthClientService) { }

  // private static extractData(res: Response) {
  //   return res || { };
  // }

  generatedBFSId(type: string): Promise<NewSubmission> {
     return this.httpAuth.get(environment.apiUrl + '/newsubmission/' + type);
  }

  uploadAdfDocument( file): Promise<SubmissionFileItem> {
     const formData = new FormData();
     formData.append('file', file);
     return this.httpAuth.post(environment.apiUrl + '/submission/' + this.ss.bfsId + '/so/attach/ads/', formData);
   }

  emailVerfication( emailId): Promise<SubmissionFileItem> {
     const params = new HttpParams().set('email', emailId);
     return this.httpAuth.post(environment.apiUrl + '/submission/' + this.ss.bfsId + '/so/email/set', params);
   }

  validatePin( pin): Promise<any> {
     const params = new HttpParams().set('pin', pin);
     return this.httpAuth.post(environment.apiUrl + '/submission/' + this.ss.bfsId + '/so/email/validate', params);
  }

  uploadAdditionalDocuments(file): Promise<SubmissionFileItem> {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpAuth.post(environment.apiUrl + '/submission/' + this.ss.bfsId + '/ve/attach/doc/', formData);
  }

   getPotentialDocumentTypes(): Promise<DocumentTypes[]> {
     return this.http.get(environment.apiUrl + '/info/potentialdocumenttypescategorized').toPromise() as Promise<DocumentTypes[]>;
   }

   //  async uploadDocSet(file, documents): Observable<DocSetInfo> {
   //  const formData = new FormData();
   //  formData.append('file', file);
   //  formData.append('documents', JSON.stringify(documents));
   //   const accessToken = await this.oktaAuth.getAccessToken();
   //   return this.http.post(environment.apiUrl + '/submission/' + this.ss.bfsId + '/ve/attach/docset/', formData , {
   //    headers: {
   //      Authorization: 'Bearer ' + accessToken
   //    }
   //  })
   //    .pipe(map(data => data as DocSetInfo));
   // }

   /* Need to cast the object   */
    fileMetaData(filemetadata: any): Promise<any> {
     return this.httpAuth.post(environment.apiUrl + '/submission/' + this.ss.bfsId + '/ve/attach/filemetadata/', filemetadata);
   }
}

