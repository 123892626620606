import {Component, OnInit} from '@angular/core';
import {BScreen, NavigationService} from '../navigation.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public  rulesAcceptance = false;

  constructor(private navigation: NavigationService) {
  }

  ngOnInit() {
  }

  startFiling() {
    this.navigation.goNext(BScreen.HOME);
  }
}
