import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DocumentTypes} from '../document-types';
import {FormGroup, FormBuilder, FormArray} from '@angular/forms';
import {RestService} from '../rest.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {SubmissionFileItem, SubmissionService, Submission, SubmissionItemDocumentInput} from '../submission.service';
import {MatStepper} from '@angular/material';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})

export class UploadComponent implements OnInit {
  public documentTypeListObservable: Observable<DocumentTypes[]>;
  private fileList: File[] = [];
  public listOfFiles: any[] = [];
  public isFirstDocument: boolean;
  private uploadedFiles: SubmissionFileItem[] = [];
  public fileMetaData = {};
  public form: FormGroup;

  constructor(private http: HttpClient,
              private fb: FormBuilder,
              private rest: RestService,
              private ss: SubmissionService,
              private stepper: MatStepper) {
    this.isFirstDocument = false;
  }


  async ngOnInit() {
    this.form = this.fb.group({
      docRanges: this.fb.array([this.fb.group({
        documentType: this.fb.array([])
      })])
    });
    this.documentTypeListObservable = this.http.get<DocumentTypes[]>(environment.apiUrl + '/info/potentialdocumenttypescategorized');
  }


  onFileChanged(event: any) {
    for (let i = 0; i <= event.target.files.length - 1; i++) {
      const selectedFile = event.target.files[i];
      this.fileList.push(selectedFile);
      this.listOfFiles.push(selectedFile.name);
      this.rest.uploadAdditionalDocuments(selectedFile).then((data: SubmissionFileItem) => {
        this.uploadedFiles.push(data);
        console.log(this.uploadedFiles);
      });
    }
    if (this.listOfFiles.length > 1) {
      this.addNewDocRanges();
    } else {
      this.isFirstDocument = true;
    }
  }

  addNewDocRanges() {
    const control = this.form.controls.docRanges as FormArray;
    control.push(this.fb.group({
      documentType: this.fb.array([])
    }));
  }


  addNext(index: number) {
    const control = (this.form.controls.docRanges as FormArray).at(index).get('documentType') as FormArray;
    control.push(this.createItem());
  }

  removeSelectedFile(index: number) {
    this.listOfFiles.splice(index, 1);
    this.fileList.splice(index, 1);
    const control = this.form.controls.docRanges as FormArray;
    control.removeAt(index);
    if (this.listOfFiles.length === 0) {
      this.addNewDocRanges();
      this.isFirstDocument = false;
    }
  }

  createItem() {
    return this.fb.group({
      documentCode: '',
      startPage: '',
      endPage: ''
    });
  }

  updateMetadata() {
    let i = 0;
    if (this.listOfFiles.length >= 1) {
      for (const entry of this.form.controls.docRanges.value) {
        this.fileMetaData[this.uploadedFiles[i].id] = entry.documentType as SubmissionItemDocumentInput[];
        // this.uploadedFiles[i].documents = entry.documentType as SubmissionItemDocument[];
        // this.map[i] = this.uploadedFiles[i];
        i++;
      }
      this.rest.fileMetaData(this.fileMetaData).then((sub: Submission) => {
        this.ss.submission = sub;
      });
    }
  }

  goBack() {
    // this.updateMetadata();
    this.stepper.previous();
  }

  goNext() {
    this.updateMetadata();
    this.stepper.next();
  }
}

